import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const Models = (props) => {
        const {
                className
        } = props;

        const [modal, setModal] = useState(false);

        const toggle = () => setModal(!modal);

        return (
                <div>
                        <div onClick={toggle} size="sm" >{props.topic}</div>
                        <Modal isOpen={modal} toggle={toggle} className={className}>
                                <ModalHeader style={{ 'backgroundColor': "silver" }} color="primary" toggle={toggle}>{props.topic}</ModalHeader>
                                <ModalBody>
                                        {props.children}
                                </ModalBody>
                        </Modal>
                </div>
        );
}

export default Models;
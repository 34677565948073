import React from 'react';
import { Container } from 'reactstrap';
import "./App.css";

function App() {
  return (
    <>
      <div className="DefaultBG">
        <Container>

          <h4>Welcome Soldiers!</h4><br />

          <u>Usage</u><br></br>
          Server query: <code className="code">[https://ins.vinite.eu/r/yourip] | [https://ins.vinite.eu/r/yourip:port]</code><br />
          Mod statistics: <code><a href="https://ins.vinite.eu/modstats" target="_blank">https://ins.vinite.eu/modstats</a></code><br />
          <br /><br />


          <h5>Update 02/2022</h5>
          <p>Maintenance update</p>
          <u>Changelist:</u>
          <ul>Update react packages</ul>
          <hr />

          <h5>Update 12/2021</h5>
          <p>Maintenance update</p>
          <u>Changelist:</u>
          <ul>Major upgrade React + dependencies</ul>
          <ul>Tweaked icons</ul>
          <ul>Fixed small textual typos</ul>
          <ul>Added map background Gap</ul>
          <hr />

          <h5>Update 05/2021</h5>
          <p>Support additional map.</p>
          <u>Changelist:</u>
          <ul>Added map background Citadel</ul>
          <hr />

          <h5>Update 04/2021</h5>
          <p>Support additional maps, small UI changes</p>
          <u>Changelist:</u>
          <ul>Added map backgrounds Safehouse and Temple</ul>
          <ul>Removed non functional API link</ul>
          <hr />

          <h5>Update 03/2021</h5>
          <p>In this update I added some UI tweaks and a new module to list top downloaded Sandstorm mods.</p>
          <u>Changelist:</u>
          <ul>Changed feature icons in server UI</ul>
          <ul>Changed background changing routine</ul>
          <ul>Added more map backgrounds</ul>
          <ul>Added modstats overview</ul>

          Regards,<br />
          Spec.<br />
        </Container>
      </div>
    </>
  );
}

export default App;

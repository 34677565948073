import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import {
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import Server from './Server';
import Playerstats from './Playerstats';
import Modstatoverview from './Modstatoverview';


const routing = (
  <BrowserRouter>
    <Routes>
      <Route path="/r/:serverip" element={<Server />}></Route>
      <Route path="/stats/:playerid" element={<Playerstats />}></Route>
      <Route path="/modstats" element={<Modstatoverview />}></Route>
      <Route path="/" element={<App />}></Route>
    </Routes>
  </BrowserRouter>
)

ReactDOM.render(
  routing,
  document.getElementById('root')
);

/* ReactDOM.render(
      <App />,
  document.getElementById('root')
); */

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useState, useEffect } from 'react';
import PlayerList from './components/PlayerList';
import ServerConfig from './components/ServerConfig';
import ModList from './components/ModList';
import NavbarComponent from './components/Navbar';
import "./App.css";

function displayError(error) {
  return (
    <div className="ErrorBG">
      <h1>ERROR</h1><h4>{error}</h4>
    </div>
  )
};

function displayNoSupport() {
  return (
    <div className="DefaultBG">
      <div className="notsupported">
        <h3>Sorry, not supported game type.</h3>
      </div>
    </div>
  )
};

function Server(props) {
  //console.log(window.location.pathname.split('/')[2])
  const serverip = window.location.pathname.split('/')[2]
  const pathname = window.location.pathname
  // const gametype=props.raw.folder
  // console.log(props)
  //const [serverconfig, setserverconfig] = useState({ "state": "loading" });
  const [serverconfig, setserverconfig] = useState(0);
  const [loadinganim, setloadinganim] = useState({ "loading": true });

  const fetchData = async () => {
    setloadinganim({ "loading": true })
    fetch('https://insapi.vinite.eu/api/sandstorm/' + serverip)
      .then(response => response.json())
      .then(resultData => {
        setserverconfig(resultData);
        setloadinganim({ "loading": false })
      })
      .catch(error => setserverconfig({ "state": "error", "msg": "Server info could not be retrieved." }))
  }

  // http://localhost:8010/proxy/api/104.243.35.239:27102
  useEffect(() => {
    fetchData();
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 30000);
    return () => clearInterval(interval);
  }, [])

  function getMap(map) {
    switch (map) {
      case 'Sinjar': return 'SinjarBG';
      case 'Oilfield': return 'OilfieldBG';
      case 'Town': return 'TownBG';
      case 'Farmhouse': return 'FarmhouseBG';
      case 'Precinct': return 'PrecinctBG';
      case 'Mountain': return 'MountainBG';
      case 'Canyon': return 'CanyonBG';
      case 'Compound': return 'CompoundBG';
      case 'Ministry': return 'MinistryBG';
      case 'Powerplant': return 'PowerplantBG';
      case 'Buhriz': return 'BuhrizBG';
      case 'Tell': return 'TellBG';
      case 'Bab': return 'BabBG';
      case 'TORO': return 'ToroBG';
      case 'CompoundCity_Landscape': return 'CompoundCityLandscapeBG';
      case 'Highlands': return 'HighlandsBG';
      case 'MountainPass': return 'MountainPassBG';
      case 'Macedonia': return 'MacedoniaBG';
      case 'Panzerschlacht': return 'PanzerschlachtBG';
      case 'Frost': return 'FrostBG';
      case 'Sheds': return 'ShedsBG';
      case 'Prison': return 'PrisonBG';
      case 'Paluja': return 'PalujaBG';
      case 'Coastline': return 'CoastlineBG';
      case 'Diplomacy': return 'DiplomacyBG';
      case 'LastLight': return 'LastlightBG';
      case 'Mintaqah': return 'MintaqahBG';
      case 'Mud_Village': return 'MudvillageBG';
      case 'Oasis': return 'OasisBG';
      case 'SaltMine': return 'SaltmineBG';
      case 'SultanOfDrugs': return 'SultanofdrugsBG';
      case 'Temple': return 'TempleBG';
      case 'Safehouse': return 'SafehouseBG';
      case 'Citadel': return 'CitadelBG';
      case 'Gap': return 'GapBG';
      default: return "DefaultBG"
    }
  }
  //console.log(loadinganim.loading)
  // console.log(serverconfig)
  return (
    <>
      {typeof serverconfig.Info !== 'undefined' && serverconfig.Info.folder === "sandstorm" ?
        <div className={getMap(serverconfig.Info.map)}>
          <NavbarComponent config={pathname} />
          <ServerConfig config={serverconfig} loadinfo={loadinganim.loading} />
          <PlayerList config={serverconfig} />
          {typeof serverconfig.Mods.data !== 'undefined' &&
            <ModList config={serverconfig.Mods} />
          }
        </div>
        : serverconfig.state === "error" ? displayError(serverconfig.msg)
          : typeof serverconfig.Info !== 'undefined' && serverconfig.Info.folder !== "sandstorm" ? displayNoSupport()
            : "Loading"}
    </>
  );
}


export default Server;

import React from 'react';
import { Container } from 'reactstrap';
import "../App.css";
import {
  Row, Col
} from 'reactstrap';
import ModCard from './ModCard';


export default function ModStats(props) {
  // console.log("Mods:", props)
  return (
    <>
      <Container className="ModsContainer">
        <Row className="modsRowHeader">
          <Col>Sandstorm Mods with over 50K downloads.</Col>
        </Row>
        <Row>
          <ModCard config={props.config} limit={50000} />
        </Row>
      </Container>
    </>
  );
}
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import "../App.css";
import { BsToggle2On } from 'react-icons/bs';
import { BsToggle2Off } from 'react-icons/bs';
import { GiNightSky } from 'react-icons/gi';
import { BsSun } from 'react-icons/bs';
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";

const override = css`
  text-align: right;
  display: block;
`;

export default function ServerConfig(props) {
  //console.log(props)
  return (
    <>
      <Container className="ServerContainer">
        <Row className="serverRowHeader">
          <Col></Col>
        </Row>
        <Row>
          <Col xs="3" className="d-none d-md-block">Name</Col>
          <Col xs="7">{props.config.Info.name}</Col>
          <Col xs="2">
            <ScaleLoader
              css={override}
              height={14}
              width={4}
              radius={3}
              margin={1}
              color={"#000"}
              loading={props.loadinfo}
            />
          </Col>
        </Row>

        <Row>
          <Col xs="3">Players</Col>
          <Col xs="auto">{props.config.Info.players} / {props.config.Info.max_players} </Col>
          <Col></Col>
        </Row>

        <Row>
          <Col xs="3">Mode</Col>
          <Col xs="auto">{props.config.Rules.GameMode_s}</Col>
          <Col></Col>
        </Row>

        <Row>
          <Col xs="3">Map</Col>
          <Col xs="auto">{props.config.Info.map}</Col>
          <Col></Col>
        </Row>

        <Row>
          <Col xs="3">Password</Col>
          <Col xs="auto">{props.config.Rules.Pwd_b === "true" ? <BsToggle2On /> : <BsToggle2Off />}</Col>
          <Col></Col>
        </Row>
        <Row>
          <Col xs="3">Mutators</Col>
          <Col xs="auto">{props.config.Rules.Mutated_b === "true" ? <BsToggle2On /> : <BsToggle2Off />}</Col>
          <Col></Col>
        </Row>

        <Row>
          <Col xs="3">Ranked</Col>
          <Col xs="auto">{props.config.Rules.RankedServer_b === "true" ? <BsToggle2On /> : <BsToggle2Off />}</Col>
          <Col></Col>
        </Row>

        <Row>
          <Col xs="3">Mods</Col>
          <Col xs="auto">{props.config.Rules.Mods_b === "true" ? <BsToggle2On /> : <BsToggle2Off />}</Col>
          <Col></Col>
        </Row>
        <Row>
          <Col xs="3">Daylight Cycle</Col>
          <Col xs="auto">{props.config.Rules.Day_b === "true" ? <BsSun /> : <GiNightSky />}</Col>
          <Col></Col>
        </Row>
      </Container>
    </>
  )
}
import React from 'react';
import "../App.css";
import {
  Card, CardImg, CardText, CardTitle, CardSubtitle, CardBody,
  CardLink, CardFooter,
  Row, Col
} from 'reactstrap';
import Moment from 'react-moment';
import { BiDownload } from 'react-icons/bi';
import { BsCalendar2Range } from 'react-icons/bs';
import { BiPieChart } from 'react-icons/bi';



export default function ModCard(props) {
  // console.log("data:", props.config)
  return (
    <>
      {props.config.map((mod, index) =>
        mod.stats.downloads_total > props.limit ?
          <Col key={index} md="4" lg="3" sm="12">
            <Card className="modsCardClass" style={{ backgroundColor: '#FFF', borderColor: '#000', opacity: '0.8' }}>
              <CardImg top width="100%" src={mod.logo.thumb_320x180} alt={mod.name} />
              <CardBody>
                <CardTitle>
                  <CardLink href={mod.profile_url}><b>{mod.name}</b></CardLink>
                </CardTitle>
                <CardSubtitle tag="h6" className="mb-2 text-muted">by {mod.submitted_by.username}</CardSubtitle>
                <CardText>{mod.summary.length > 60 ? mod.summary.slice(0, 60) + "..." : mod.summary}</CardText>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col xs="2">
                    <BiPieChart />
                  </Col>
                  <Col xs="10">
                    {mod.stats.popularity_rank_position} / {mod.stats.popularity_rank_total_mods}
                  </Col>
                  <Col xs="2">
                    <BiDownload />
                  </Col>
                  <Col xs="10">
                    {mod.stats.downloads_total}
                  </Col>
                  <Col xs="2">
                    <BsCalendar2Range />
                  </Col>
                  <Col xs="10">
                    <Moment unix format="YYYY/MM/DD">
                      {mod.date_updated}
                    </Moment>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
          : null
      )}
    </>
  );
}
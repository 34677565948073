import React, { useState, useEffect } from 'react';
import "./App.css";
import StatsTagLines from './components/StatsTagLines';
import StatsGeneric from './components/StatsGeneric';

function Playerstats(props) {
  const playerid = window.location.pathname.split('/')[2]
  console.log(playerid)
  const [playerstats, setplayerstats] = useState({ "state": "loading" });

  const fetchData = async () => {
    //fetch('http://localhost:8010/proxy/sandstorm/player/76561197977339181')
    //fetch('http://localhost:8010/proxy/api/player/stats/?UniqueId=' + playerid)
    fetch('https://insapi.vinite.eu/api/sandstormstats/' + playerid)
      .then(response => response.json())
      .then(resultData => {
        setplayerstats(resultData);
      })
      .catch(error => setplayerstats({ "state": "error", "msg": "Server info could not be retrieved." }))
  }

  // http://localhost:8010/proxy/api/104.243.35.239:27102
  useEffect(() => {
    fetchData();
  }, [])

  return (
    <>
      <div className="DefaultBG">
        <StatsGeneric data={playerstats} />
        <StatsTagLines data={playerstats.PlayerTaglines} />
      </div>
    </>
  );
}


export default Playerstats;
import React from 'react';
import "../App.css";
import Modals from "./Modals";
import {
  EmailShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WeiboShareButton,
  EmailIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  WeiboIcon
} from "react-share";

export default function SocialShare(props) {
  // console.log(props)
  const shareUrl = 'https://ins.vinite.eu' + props.config;
  const title = 'INS::SA monitor';
  return (
    <>
      <Modals topic="SOCIAL SHARE">
        <p>Share your server monitoring page on social networks with the links below.</p>
          <EmailShareButton
            url={shareUrl}
            subject={title}
            body="Hej check out this Insurgency Sandstorm server monitor!"
            style={{ paddingRight: '15px' }}
          >
            <EmailIcon size={32} />
          </EmailShareButton>
          <RedditShareButton
            url={shareUrl}
            title={title}
            windowWidth={660}
            windowHeight={460}
            style={{ paddingRight: '15px' }}
          >
            <RedditIcon size={32} />
          </RedditShareButton>

          <TelegramShareButton
            url={shareUrl}
            title={title}
            style={{ paddingRight: '15px' }}
          >
            <TelegramIcon size={32} />
          </TelegramShareButton>
          <TwitterShareButton
            url={shareUrl}
            title={title}
            style={{ paddingRight: '15px' }}
          >
            <TwitterIcon size={32} />
          </TwitterShareButton>
          <WhatsappShareButton
            url={shareUrl}
            title={title}
            separator=":: "
            style={{ paddingRight: '15px' }}
          >
            <WhatsappIcon size={32} />
          </WhatsappShareButton>
          <WeiboShareButton
            url={shareUrl}
            title={title}
            style={{ paddingRight: '15px' }}
          >
            <WeiboIcon size={32} />
          </WeiboShareButton>
      </Modals>
    </>
  );
}
import React, { useState } from 'react';
import { Container } from 'reactstrap';
import SocialShare from './SocialShare';
import "../App.css";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap';
import Modals from "./Modals";

export default function NavbarComponent(props) {
  //console.log(props)
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      <Container className="NavbarContainer">
        <Navbar className="navbar-light" expand="md">
          <NavbarBrand href="/">INS::SA monitor</NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink>
                  <Modals topic="DEV">
                    <p>This server monitor is developed by <a href='https://github.com/pblaas' target='_new'>pb</a>.</p>
                    <p>In game and in the steam fora I go by the name of Spec.</p>
                    <p>
                      If you like to provide any feedback to this monitor please use the steam fora here:
                      <a href="https://steamcommunity.com/app/581320/discussions/1/4698886342117752169/" target="_new"> Steam discussions</a>
                    </p>
                    <p> Have fun!</p>
                  </Modals>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Modals topic="TECH">
                    For this server monitor the following tech was used:
                    <ul>
                      <li><a href="https://reactjs.org/" target="_new"> React</a> library</li>
                      <li>Bootstrap</li>
                      <li><a href="https://reactstrap.github.io/" target="_new"> Reactstrap</a></li>
                      <li><a href="https://react-icons.netlify.com/#/" target="_new">React Icons</a></li>
                    </ul>
                  </Modals>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <SocialShare config={props.config} />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://ins.vinite.eu/modstats" target="_new">MODSTATS
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  SOURCES
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem>
                    <NavLink href="https://steamcommunity.com/app/581320/discussions/1/4698886342117752169/" target="_new">Steam discussions
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="https://insurgencysandstorm.mod.io" target="_new">Mod.io
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="https://newworldinteractive.com/category/insurgency-sandstorm/" target="_new">NWI</NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
            <NavbarText>Powered by Spec.</NavbarText>
          </Collapse>
        </Navbar>
      </Container>
    </>
  );
}
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import "../App.css";
import { FaUserClock } from 'react-icons/fa';
import { FaUser } from 'react-icons/fa';
import { GrPlan } from 'react-icons/gr';
import { RiCopyrightFill } from 'react-icons/ri';
import { GiRank1 } from 'react-icons/gi';
import { GiRank2 } from 'react-icons/gi';
import { GiRank3 } from 'react-icons/gi';


export default function PlayerList(props) {
  // console.log("player:", props)
  return (
    <>
      <Container className="PlayerContainer">
        <Row className="playerRowHeader">
          <Col><FaUser /></Col>
          <Col><GrPlan className="grIcon" /></Col>
          <Col><FaUserClock /></Col>
        </Row>
        {props.config.Players.length > 0 ? props.config.Players.map((player, index) => (
          <Row key={index}>
            <Col>
              {player.score >= 0 && player.score < 1000 ? <GiRank1 className="rank" /> :
                player.score >= 1000 && player.score < 2000 ? <GiRank2 className="rank-green" /> :
                  player.score >= 2000 ? <GiRank3 className="rank-purple" /> :
                    ''
              }
              {player.name}
              {player.name === "Spec" && <RiCopyrightFill className="copyright" />}
            </Col>
            <Col className="d-none d-md-block">{player.score}</Col>
            <Col>{Math.round(player.duration / 60)}</Col>
          </Row>
        )) : "No soldiers online"}
      </Container>
    </>
  );
}
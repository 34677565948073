import React from 'react';
import "../App.css";
import {
    Card, CardText, CardBody,
    CardFooter,
    Container, Row, Col
} from 'reactstrap';

export default function StatsTagLines(props) {
    const f = function (v) {
        var c = '';
        if (v) {
            c = 1 + v;
        } else {
            c = 1;
        }
        return c;
    }

    const stats = {};
    if (props.data) {
        props.data.forEach(element => {
            stats[element.TaglineName] = f(stats[element.TaglineName]);
        })
    }

    const entries = Object.entries(stats)

    return (
        <>
            <Container className="StatsContainer">
                <Row className="statsRowHeader">
                    <Col>Match Trophies</Col>
                </Row>
                <Row>
                    {entries.map((e, index) => (
                        <Col key={index} md="4">
                            <Card className="statsCardClass" style={{ backgroundColor: '#FFF', borderColor: '#000', opacity: '0.8' }}>
                                <CardBody>
                                    <CardText>{e[0].split('_')[1]}</CardText>
                                </CardBody>
                                <CardFooter>
                                    {e[1]}
                                </CardFooter>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </>
    );
}
import React from 'react';
import "../App.css";
import { Container, Row, Col } from 'reactstrap';
import { ListGroup, ListGroupItem } from 'reactstrap';

export default function StatsGeneric(props) {

    const entries = Object.entries(props.data)
    const stats = Object.fromEntries(entries);

    const genericStats = ["AverageExperienceGained",
        "AverageKillDeath",
        "AverageObjectiveTicks",
        "AverageObjectives",
        "Balance",
        "BaseExperience",
        "BonusExperience",
    ]
    //console.log(genericStats)
    function ReturnGenStat(data) {
        return parseFloat(stats[data]).toFixed(2)
    }
    console.log(stats)
    if (stats.Meta !== 'undefined') {
        return (
            <>
                <Container className="StatsContainer">
                    <Row className="statsRowHeader">
                        <Col>Generic stats for {stats.UniqueId}</Col>
                    </Row>
                First recorded match on {typeof stats.Meta !== 'undefined' ?
                        stats.Meta.FirstPlayTime
                        : null
                    }
                    <ListGroup>
                        {genericStats.map((e, index) =>
                            <ListGroupItem key={index} style={{ backgroundColor: '#FFF', borderColor: '#000', opacity: '0.8' }}>
                                {e} : {ReturnGenStat(e)}
                            </ListGroupItem>
                        )}
                    </ListGroup>
                </Container>
            </>
        );
    };
}
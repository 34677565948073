import React, { useState, useEffect } from 'react';
import "./App.css";
import ModStats from './components/ModStats';

function Modstatoverview(props) {

  const [modstats, setmodstats] = useState({ "state": "loading" });
  const fetchData = async () => {
    //fetch('http://localhost:8010/proxy/sandstorm/player/76561197977339181')
    //fetch('http://localhost:8010/proxy/api/player/stats/?UniqueId=' + playerid)
    fetch('https://insapi.vinite.eu/api/sandstormmodstats')
      .then(response => response.json())
      .then(resultData => {
        setmodstats(resultData);
      })
      .catch(error => setmodstats({ "state": "error", "msg": "Server info could not be retrieved." }))
  }

  useEffect(() => {
    fetchData();
  }, [])
  // console.log(modstats)
  return (
    <>
      <div className="DefaultBG">

        {typeof modstats.data !== 'undefined' &&
          <ModStats config={modstats.data} />
        }
      </div>
    </>
  );
}


export default Modstatoverview;
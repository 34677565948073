import React from 'react';
import { Container } from 'reactstrap';
import "../App.css";
import {
  Row, Col
} from 'reactstrap';
import ModCard from './ModCard';


export default function ModList(props) {
  //console.log("Mods:", props)
  return (
    <>
      <Container className="ModsContainer">
        <Row className="modsRowHeader">
          <Col>Installed Mods</Col>
        </Row>
        <Row>
          <ModCard config={props.config.data} limit={1} />
        </Row>

      </Container>
    </>
  )
}